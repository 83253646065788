import React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ScrollUp from "../components/ScrollUp"
import Accordion from "../components/Accordion"
import SEO from "../components/SEO"
import { useIntl } from "gatsby-plugin-intl"
export default function Service({ pageContext }) {
  const intl = useIntl()
  console.log(pageContext)
  return (
    <>
      <SEO />
      <div className="wrapper">
        <Header />
        <section className="text-content-section pb-90">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="about__img">
                  <img
                    src={pageContext.services.mainImage.asset.fluid.src}
                    alt="about"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="text__block">
                  <h5 className="text__block-title">
                    {intl.locale === "en"
                      ? pageContext.services.serviceTitle_en
                      : pageContext.services.serviceTitle}
                  </h5>
                  <p className="text__block-desc">
                    {intl.locale === "en"
                      ? pageContext.services.service_content_en[0].children[0]
                          .text
                      : pageContext.services.service_content[0].children[0]
                          .text}
                  </p>
                  <p className="mt-20">
                    {intl.locale === "en"
                      ? pageContext.services.service_features_title_en
                      : pageContext.services.service_features_title}
                  </p>
                  <ul>
                    {intl.locale === "en"
                      ? pageContext.services.service_features_content_en.map(
                          (item, index) => {
                            return <li key={index}>{item.children[0].text}</li>
                          }
                        )
                      : pageContext.services.service_features_content.map(
                          (item, index) => {
                            return <li key={index}>{item.children[0].text}</li>
                          }
                        )}
                  </ul>
                  <p />
                </div>

                <h5 className="fz-25 mt-20">
                  {intl.locale === "en"
                    ? pageContext.services.questionTitle_en
                    : pageContext.services.questionTitle}
                </h5>
                <div id="accordion">
                  {intl.locale === "en"
                    ? pageContext.services.service_question_en.map(question => {
                        return (
                          <Accordion
                            key={question._key}
                            collapseId={`collapse-${question._key}`}
                            title={question.title}
                            desc={question.content}
                          />
                        )
                      })
                    : pageContext.services.service_question.map(question => {
                        return (
                          <Accordion
                            key={question._key}
                            collapseId={`collapse-${question._key}`}
                            title={question.title}
                            desc={question.content}
                          />
                        )
                      })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <ScrollUp />
      </div>
    </>
  )
}
