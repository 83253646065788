import React, { useState } from "react"

export default function Accordion({ title, desc, collapseId }) {
  const [activeIcon, setActiveIcon] = useState(false)
  return (
    <div
      className={activeIcon ? "accordion-item opened" : "accordion-item"}
      onClick={() => setActiveIcon(!activeIcon)}
    >
      <div className="accordion-item__header">
        <a className="accordion-item__title">{title}</a>
      </div>

      <div className={activeIcon ? "collapse-show" : "collapse"}>
        <div className="accordion-item__body">
          <p>{desc}</p>
        </div>
      </div>
    </div>
  )
}
